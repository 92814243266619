
// Body
// $body-bg: #f5f8fa;
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #0070BB;
// $brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
// $brand-danger: #bf5329;
$brand-danger: #ED2F29;
$state-danger-text: #ED2F29;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
//$font-family-sans-serif: "Raleway", sans-serif;
$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;
$navbar-default-link-color: #fff;
$navbar-default-link-active-bg: transparent;
$navbar-default-link-active-color: #ccc;
$navbar-default-link-hover-color: #ccc;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
$panel-danger-text: #fff;
$panel-danger-heading-bg: $brand-danger;
$panel-danger-border: $brand-danger;

$border-radius-base: 0;