
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// DatePicker
@import "bootstrap-datepicker3.min.css";

// Select2
@import "select2.css";

// Select2
@import "select2-bootstrap.css";

// Summernote
@import "summernote.css"; 

html,
body {
	margin:0;
	padding:0;
	height:100%;
}

#app {
	min-height:100%;
	position:relative;
}

#content {
	padding-bottom: 110px; //tyle co wysokość stopki
}

//kolor primary dla select2 multiple
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    background-color: $brand-primary;
    border-color: darken($brand-primary, 5%);;
    color: #fff;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
}

//odstęp między przyciskami pull-right
.margin-left {
	margin-left: .3em;
}

.margin-bottom {
	margin-bottom: .5em;
}

.div-danger {
	//background-color: lighten($brand-danger, 50%);
	color: $brand-danger;
	font-weight: bold;
}

.navbar {
	background: url('/img/naglowek_login.jpg') no-repeat center center ;
  	-webkit-background-size: cover; /* For WebKit*/
    -moz-background-size: cover;    /* Mozilla*/
    -o-background-size: cover;      /* Opera*/
    background-size: cover; 
}


// __________________________________LOGIN_________________________________
.margin-top-5 {
	margin-top: 5px;
	// padding-top: 5px;
}

.padding-top-5 {
	padding-top: 5px;
}

.padding-bottom-5 {
	padding-bottom: 5px;
}

.bold {
	font-weight: 700;
}

.no-padding {
	padding: 0;
}

.no-padding-horizontal {
	padding-top: 0;
	padding-bottom: 0;
}

.no-margin-horizontal {
	margin-top: 0;
	margin-bottom: 0;

}

.login-panel {
	border: 1px solid $brand-primary;
	border-top: 5px solid $brand-danger;
	height: 255px;
}

.login-panel img {
	height: 250px;
	width: auto;
}

.login-panel div {
	padding: 0;
}

.login-panel .row {
	text-align: center;
}

.login-div {
	padding-top: 10px;
	border-bottom: 1px solid $brand-primary;
}

.login-panel label > img {
	// width: 25px;
	height: 25px;
	padding-right: 10px;
}

.login-panel form {
	padding-top: 20px;
	padding-left: 70px;
}

h2 {
	color: $brand-primary;
}

// ____________________________ANSWER______________________________________
// 

.margin-left-blue {
	border-left: 5px solid $brand-primary;
	margin-bottom: 5px;
}

.blue-bg {
	color: #fff;
	background-color: $brand-primary;
}

.blue-bg a{
	color: #fff;
}

.center {
	text-align: center;
}

.gray {
	background-color: #ccc;
	color: $brand-primary;
	margin-bottom: 25px;
}

#answer_form {
	padding-bottom: 50px;
}

#answer_form hr {
	display: block;
    height: 1px;
    border-top: 1px solid $brand-primary;
}

.footer {
	border-top: 5px solid $brand-danger;
	margin: 0;
	// margin-top: 100px;
	position: absolute;
    left: 0;
    bottom: 0;
    height: 110px;
    width: 100%;
    overflow:hidden;
}

.pytanie {
	display: inline;
	font-weight: 700;
	font-size: 18px;
	vertical-align: center;
	margin-bottom: 20px;
}

#answer_form hr.white {
	display: block;
    height: 1px;
    border-top: 1px solid #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-left-1 {
	margin-left: 1px;
}

.margin-right-1 {
	margin-right: 1px;
}